import cms from '../../../api/cms';
import { NotFoundError } from '../../../api/errors';

import { Locale } from '../../../utils/enums/languages';

import { authorQuery, mainImageQuery, translationUrlQuery } from '../common';

const blogQuery = `
_id,
'lang': __i18n_lang,
publishedAt,
title,
slug,
excerpt,
body,
author->${authorQuery},
mainImage{
  ${mainImageQuery}
},
'translationUrl': {
  ${translationUrlQuery}
}
`;

/**
 * API helper for getting a list of blog posts
 * @param {Locale} [language=Locale.SI]
 * @returns {Promise<Object[]>}
 */
export const getBlogs = async (language = Locale.SI) => {
  return cms.fetch(
    `
    *[_type == "post" && __i18n_lang == $language] | order(_createdAt desc){
      ${blogQuery}
    }
    `,
    { language }
  );
};

/**
 * API helper for getting a list of recent blog posts. Defaults to the 3 most recent blog posts.
 * @param {Number} [amount=3]
 * @param {Locale} [language=Locale.SI]
 * @returns {Promise<Object[]>}
 */
export const getRecentBlogs = async (amount = 3, language = Locale.SI) => {
  return cms.fetch(
    `
    *[_type == "post" && __i18n_lang == $language] | order(_createdAt desc){
      ${blogQuery}
    }[0...$amount]
    `,
    { amount, language }
  );
};

/**
 * API helper for getting a blog post for given slug
 * @param {String} slug
 * @param {Locale} [language=Locale.SI]
 * @returns {Promise<Object[]>}
 */
export const getBlogBySlug = async (slug, language = Locale.SI) => {
  const response = await cms.fetch(
    `
    *[_type == "post" && slug.current == $slug]{
      ${blogQuery}
    }
    `,
    { slug, language }
  );

  if (response?.length === 0) {
    throw new NotFoundError();
  }

  return response;
};

import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { SubpagesHero } from '../../components/Hero';
import { ImageSection } from '../../components/Sections';
import { Container, Columns } from '../../components/Helpers';

import about_page from '../../content/about_page.json';
import team from '../../content/team.json';

import './About.scss';

class About extends Component {
  componentDidMount() {
    let title = about_page[this.props.currentLanguage].page_title.replace(
      /[^a-zA-Z0-9-_\s]/g,
      ''
    );
    document.title = `${title} | Optifarm`;
    ReactGA.set({ title: document.title, page: document.title });
  }
  render() {
    const about_page_data = about_page[this.props.currentLanguage];
    const { sections } = about_page_data;
    const team_data = team[this.props.currentLanguage];

    return (
      <div className="about-page">
        <SubpagesHero
          data={about_page_data}
          hero_image={about_page_data.featured_image}
        />
        <div className="subpage-main-content">
          {sections.map((section, i) => {
            return <ImageSection data={section} key={i} />;
          })}
          <div className="subpage-section">
            <Container>
              <Columns>
                <div className="section-content column">
                  <h2 className="section-title">
                    {about_page_data.team.title}
                  </h2>
                  <h3 className="section-subtitle">
                    {about_page_data.team.subtitle}
                  </h3>
                </div>
              </Columns>
            </Container>
            <Container>
              <Columns className="is-multiline is-mobile persist-order">
                {team_data.map(({ avatar, name, role }) => (
                  <div className="column is-one-fifth-widescreen is-one-quarter-desktop is-one-third-tablet is-full-mobile">
                    <div className="team-member">
                      <figure className="image is-128x128 is-rounded">
                        <img src={avatar} alt={name} />
                      </figure>
                      <div className="content">
                        <h3 className="title is-4">{name}</h3>
                        <p className="subtitle is-6">{role}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Columns>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: getActiveLanguage(state.localize).code
});

export default connect(mapStateToProps)(About);

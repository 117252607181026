import React, { useEffect, useMemo } from 'react';

import { BlogPostCard } from '../../components/Cards';
import { Container, Columns, SectionStarter } from '../../components/Helpers';
import { SubpagesHero } from '../../components/Hero';

import blogPage from '../../content/blog_page.json';

import { useCurrentLanguage } from '../../hooks';

import { useBlogs } from '../../services/query/blogs';

import { getBlogPostBaseUrl } from '../../utils/helpers/blogs';
import { setPageTitleAttribute } from '../../utils/helpers/seo';

const Blog = () => {
  const lang = useCurrentLanguage();
  const blogPostBaseUrl = getBlogPostBaseUrl(lang);
  const posts = useBlogs();

  const data = useMemo(() => blogPage[lang], [lang]);

  useEffect(() => {
    setPageTitleAttribute(blogPage[lang].page_title);
  }, [lang]);

  return (
    <div className="blog-page">
      <SubpagesHero data={data} hero_image={data.featured_image} />
      <SectionStarter />
      <div className="subpage-main-content">
        <Container className="blog-container">
          <Columns className="blogposts is-multiline">
            {posts.data?.map((post) => (
              <div key={post._id} className="column is-one-third">
                <BlogPostCard {...post} base_url={blogPostBaseUrl} />
              </div>
            ))}
          </Columns>
        </Container>
      </div>
    </div>
  );
};

export default Blog;

import { Locale } from '../../utils/enums/languages';

export const translations = {
  [Locale.EN]: {
    fund: 'Fund:',
    operationDuration: 'Duration of the operation:',
    partners: 'Partners:',
    operationTotalValue:
      'Total value of the operation (net eligible costs + VAT):'
  },
  [Locale.SI]: {
    fund: 'Sklad:',
    operationDuration: 'Trajanje operacije:',
    partners: 'Partnerji:',
    operationTotalValue:
      'Skupna vrednostoperacije (neto upravičeni stroški + DDV):'
  }
};

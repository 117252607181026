import React from 'react';

import {
  Container,
  Columns,
  SectionStarter
} from '../../../components/Helpers';
import { BlogPostCard } from '../../../components/Cards';

import { useRecentBlogs } from '../../../services/query/blogs';

import {
  getBlogPostBaseUrl,
  getBlogPostExcerpt
} from '../../../utils/helpers/blogs';

/**
 * Component rendering Blog Posts section
 * @param {Object} props
 * @param {Object} props.data
 * @returns {JSX.Element}
 */
const BlogSection = ({ data: { front_page } }) => {
  const posts = useRecentBlogs();

  return (
    <section id="blog">
      <SectionStarter />
      <Container>
        <h2 className="section-title">{front_page.title}</h2>
        <h3 className="section-subtitle">{front_page.subtitle}</h3>
        <p className="section-excerpt">{front_page.excerpt}</p>
        <Columns className="blogposts">
          {posts.data?.map((post) => (
            <BlogPostCard
              {...post}
              key={post._id}
              excerpt={getBlogPostExcerpt(post)}
              base_url={getBlogPostBaseUrl()}
            />
          ))}
        </Columns>
      </Container>
    </section>
  );
};

export default BlogSection;

import { useQuery } from '@tanstack/react-query';

import { NotFoundError } from '../../../api/errors';

import { useCurrentLanguage } from '../../../hooks';

import { useTranslatedEntity } from '../common';

import { getBlogBySlug, getBlogs, getRecentBlogs } from './blogs.api';
import { blogsQueryKeys } from './blogs.keys';

export const useBlogs = () => {
  const lang = useCurrentLanguage();

  return useQuery({
    queryKey: blogsQueryKeys.list(lang),
    queryFn: () => getBlogs(lang),
    enabled: !!lang
  });
};

export const useRecentBlogs = () => {
  const lang = useCurrentLanguage();

  return useQuery({
    queryKey: blogsQueryKeys.recent(lang),
    queryFn: () => getRecentBlogs(3, lang),
    enabled: !!lang
  });
};

export const useBlog = (slug) => {
  const lang = useCurrentLanguage();
  const data = useQuery({
    queryKey: blogsQueryKeys.read(slug, lang),
    queryFn: () => getBlogBySlug(slug, lang),
    enabled: !!slug && !!lang
  });

  const blog = useTranslatedEntity({
    data,
    lang,
    redirectBaseUri: 'blog'
  });

  return {
    ...data,
    data: blog,
    isNotFound: data.error?.code === NotFoundError.code
  };
};

import React, { Component } from 'react';
import { Container } from '../../components/Helpers';

const WireframeTemplate = () => (
  <section id="post-wireframe">
    <header>
      <div className="wf-post-title"></div>
      <div className="wf-post-meta"></div>
    </header>
    <Container>
      <article>
        <div className="wf-post-subtitle"></div>
        <div className="wf-post-text-container">
          <div className="wf-post-text"></div>
          <div className="wf-post-text"></div>
          <div className="wf-post-text"></div>
          <div className="wf-post-text"></div>
          <div className="wf-post-text"></div>
        </div>
        <div className="wf-post-image"></div>
        <div className="wf-post-text-container">
          <div className="wf-post-text"></div>
          <div className="wf-post-text"></div>
          <div className="wf-post-text"></div>
          <div className="wf-post-text"></div>
          <div className="wf-post-text"></div>
        </div>
        <div className="wf-post-footer"></div>
      </article>
    </Container>
  </section>
);

export default WireframeTemplate;

export const authorQuery = `
{
  _id,
  'name': authorName.[$language]
}
`;

export const mainImageQuery = `
asset->{
  _id,
  url
}
`;

export const translationUrlQuery = `
defined(__i18n_refs) => __i18n_refs[0]->{
  'lang': __i18n_lang,
  'slug': slug.current
},
defined(__i18n_base) => __i18n_base->{
  'lang': __i18n_lang,
  'slug': slug.current
}
`;

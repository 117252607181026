import cms from '../../../api/cms';
import { NotFoundError } from '../../../api/errors';
import { Locale } from '../../../utils/enums/languages';

import { authorQuery, mainImageQuery, translationUrlQuery } from '../common';

const projectQuery = `
_id,
'lang': __i18n_lang,
title,
slug,
excerpt,
body,
fund,
duration,
operationTotalValue,
partners,
author->${authorQuery},
mainImage{
  ${mainImageQuery}
},
'translationUrl': {
  ${translationUrlQuery}
}
`;

/**
 * API helper for getting a list of projects
 * @param {Locale} [language=Locale.SI]
 * @returns {Promise<Object[]>}
 */
export const getProjects = async (language = Locale.SI) => {
  return cms.fetch(
    `
    *[_type == "project" && __i18n_lang == $language] | order(_createdAt desc){
      ${projectQuery}
    }
    `,
    { language }
  );
};

/**
 * API helper for getting a project for given slug
 * @param {String} slug
 * @param {Locale} [language=Locale.SI]
 * @returns {Promise<Object[]>}
 */
export const getProjectBySlug = async (slug, language = Locale.SI) => {
  const response = await cms.fetch(
    `
    *[_type == "project" && slug.current == $slug]{
      ${projectQuery}
    }
    `,
    { slug, language }
  );

  if (response?.length === 0) {
    throw new NotFoundError();
  }

  return response;
};

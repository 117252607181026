import React, { useEffect } from 'react';

import { BlogPostCard } from '../../components/Cards';
import { SubpagesHero } from '../../components/Hero';
import { Container, Columns } from '../../components/Helpers';

import projects_page from '../../content/projects_page.json';

import { useCurrentLanguage } from '../../hooks';

import { useProjects } from '../../services/query/projects';
import { setPageTitleAttribute } from '../../utils/helpers/seo';

const Projects = () => {
  const lang = useCurrentLanguage();
  const { data: projects } = useProjects();
  const projects_page_data = projects_page[lang];

  useEffect(() => {
    setPageTitleAttribute(projects_page_data.page_title);
  }, [projects_page_data]);

  return (
    <div className="projects-page">
      <SubpagesHero
        data={projects_page_data}
        hero_image={projects_page_data.featured_image}
      />
      <div className="subpage-main-content">
        <div className="subpage-section">
          <Container>
            <Columns className="is-multiline is-mobile persist-order blogposts">
              {projects?.map(
                ({ author, title, body, excerpt, mainImage, slug }) => (
                  <BlogPostCard
                    key={slug}
                    author={author}
                    body={body}
                    className="is-one-third"
                    title={title}
                    excerpt={excerpt}
                    base_url={projects_page_data.slug}
                    mainImage={mainImage}
                    slug={slug}
                  />
                )
              )}
            </Columns>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Projects;

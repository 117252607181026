import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setActiveLanguage } from 'react-localize-redux';

export const useTranslatedEntity = ({ data, lang, redirectBaseUri }) => {
  const [translatedEntity, setTranslatedEntity] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.isSuccess && data.data) {
      const enitity = data.data[0];
      if (enitity.lang !== lang) {
        if (enitity.translationUrl.slug) {
          history.push(`/${redirectBaseUri}/${enitity.translationUrl.slug}`);
        } else {
          dispatch(setActiveLanguage(enitity.lang));
          setTranslatedEntity(enitity);
        }
      } else {
        setTranslatedEntity(enitity);
      }
    }
  }, [
    data.isSuccess,
    data.data,
    dispatch,
    setTranslatedEntity,
    history,
    lang,
    redirectBaseUri
  ]);

  return translatedEntity;
};

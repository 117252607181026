import SanityBlockContent from '@sanity/block-content-to-react';
import React, { useEffect } from 'react';
import { useRouteMatch, Redirect } from 'react-router-dom';

import { config } from '../../api/cms';
import { getImageUrl } from '../../api/images';

import { SanityTable } from '../../components/Sanity';
import { SubpagesHero } from '../../components/Hero';
import { Container } from '../../components/Helpers';

import { useCurrentLanguage } from '../../hooks';

import { useProject } from '../../services/query/projects';

import { setPageTitleAttribute } from '../../utils/helpers/seo';

import WireframeTemplate from '../BlogPost/WireframeTemplate';

import { translations } from './data';

const SingleProject = () => {
  const lang = useCurrentLanguage();
  const { params } = useRouteMatch();
  const project = useProject(params?.slug);

  const t = translations[lang];

  useEffect(() => {
    if (project.data) {
      setPageTitleAttribute(project.data.title);
    }
  }, [project.data]);

  if (project.isNotFound) {
    return <Redirect to="/404" />;
  }

  if (project.isLoading || !project.data) {
    return <WireframeTemplate />;
  }

  return (
    <div className="project-page">
      <SubpagesHero
        data={project.data}
        hero_image={getImageUrl(project.data?.mainImage, 1200)}
      />
      <div className="subpage-main-content">
        <div className="subpage-section">
          <Container className="content">
            <img
              src={getImageUrl(project.data?.mainImage)}
              alt={project.data?.title}
            />
            {project.data?.fund && (
              <p>
                <strong>{t.fund}</strong>{' '}
                <a
                  href={project.data.fund.fundUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {project.data.fund.fundName}
                </a>
              </p>
            )}
            <p>
              <strong>{t.operationDuration}</strong> {project.data?.duration}
            </p>
            <p>
              <strong>{t.partners}</strong> {project.data?.partners}
            </p>
            <p>
              <strong>{t.operationTotalValue}</strong>{' '}
              {project.data?.operationTotalValue}
            </p>
            <SanityBlockContent
              blocks={project.data?.body}
              projectId={config.projectId}
              dataset={config.dataset}
              serializers={{ types: { table: SanityTable } }}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default SingleProject;

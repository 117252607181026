import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';

import rootReducer from './services/reducers';

/**
 * Browser History
 * @type {History}
 */
const history = createBrowserHistory();

/**
 * Returns true if current NODE_ENV is dev env
 * @returns {Boolean}
 */
const isDevEnvironment = () => {
  return process.env.NODE_ENV === 'development';
};

const getMiddlewares = (middlewares = []) => {
  const appliedMiddlewares = applyMiddleware(...middlewares);

  if (isDevEnvironment()) {
    return composeWithDevTools(appliedMiddlewares);
  }

  return appliedMiddlewares;
};

const configureStore = () => {
  const middleware = getMiddlewares([routerMiddleware(history)]);
  const store = createStore(rootReducer(history), middleware);

  return store;
};

const store = configureStore();

export { history };
export default store;

import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { renderToStaticMarkup } from 'react-dom/server';
import ReactGA from 'react-ga';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';

import store, { history } from './store';

import { Locale } from './utils/enums/languages';

import App from './App';

import './styles/index.sass';

const languages = [
  { name: 'English', code: Locale.EN },
  { name: 'Slovenian', code: Locale.SI }
];

const target = document.getElementById('root');

let browserLanguage;
if (navigator.language.substring(0, 2) === Locale.EN) {
  browserLanguage = Locale.EN;
} else if (navigator.language.substring(0, 2) === 'sl') {
  browserLanguage = Locale.SI;
} else {
  browserLanguage = false;
}

ReactGA.initialize('UA-112387074-1');

const defaultLanguage =
  new URL(window.location.href).searchParams.get('lang') ||
  localStorage.getItem('lang') ||
  browserLanguage ||
  'en';

const root = createRoot(target);

root.render(
  <Provider store={store}>
    <LocalizeProvider
      initialize={{
        languages,
        options: { renderToStaticMarkup, defaultLanguage }
      }}
      store={store}
    >
      <ConnectedRouter onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <App />
      </ConnectedRouter>
    </LocalizeProvider>
  </Provider>
);

export class ApiError extends Error {
  constructor(props) {
    super(props);
    this.code = props.code;
    this.name = props.name;
  }
}

export class NotFoundError extends ApiError {
  static name = 'NotFound';
  static code = 404;

  constructor(props) {
    super({ ...props, code: NotFoundError.code, name: NotFoundError.name });
  }
}

import { useQuery } from '@tanstack/react-query';

import { NotFoundError } from '../../../api/errors';
import { useCurrentLanguage } from '../../../hooks';
import { Locale } from '../../../utils/enums/languages';

import { useTranslatedEntity } from '../common';

import { projectsQueryKeys } from './projects.keys';
import { getProjectBySlug, getProjects } from './projects.api';

export const useProjects = () => {
  const lang = useCurrentLanguage();

  return useQuery({
    queryFn: () => getProjects(lang),
    queryKey: projectsQueryKeys.list(lang),
    enabled: !!lang
  });
};

export const useProject = (slug) => {
  const lang = useCurrentLanguage();
  const data = useQuery({
    queryFn: () => getProjectBySlug(slug, lang),
    queryKey: projectsQueryKeys.read(slug, lang),
    enabled: !!lang
  });

  const project = useTranslatedEntity({
    data,
    lang,
    redirectBaseUri: lang === Locale.SI ? 'eu-projekti' : 'eu-projects'
  });

  return {
    ...data,
    data: project,
    isNotFound: data.error?.code === NotFoundError.code
  };
};

import BlockContent from '@sanity/block-content-to-react';
import React, { useEffect } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { getImageUrl } from '../../api/images';
import { config } from '../../api/cms';

import { Container } from '../../components/Helpers';
import { MainBtn, OutlinedBtn } from '../../components/Buttons';
import { SubpagesHero } from '../../components/Hero';
import { SanityTable } from '../../components/Sanity';

import { useCurrentLanguage } from '../../hooks';

import { useBlog } from '../../services/query/blogs';

import { getBlogPostBaseUrl } from '../../utils/helpers/blogs';
import { setPageTitleAttribute } from '../../utils/helpers/seo';
import {
  getBackToHomeLabel,
  getBackToBlogPostsLabel
} from '../../utils/helpers/strings';

import WireframeTemplate from './WireframeTemplate';

export const SingleBlogPost = () => {
  const lang = useCurrentLanguage();
  const { params } = useRouteMatch();
  const blog = useBlog(params?.slug);

  useEffect(() => {
    if (blog.data) {
      setPageTitleAttribute(blog.data.title);
    }
  }, [blog.data]);

  if (blog.isNotFound) {
    return <Redirect to="/404" />;
  }

  if (blog.isLoading || !blog.data) {
    return <WireframeTemplate />;
  }

  return (
    <div className="single-blog-post">
      <SubpagesHero
        hero_image={getImageUrl(blog.data.mainImage, 1200)}
        data={blog.data}
      />
      <section id="single-post">
        <Container className="blog-post-container content">
          <BlockContent
            blocks={blog.data.body}
            projectId={config.projectId}
            dataset={config.dataset}
            serializers={{ types: { table: SanityTable } }}
          />
          <hr />
          <div className="cta-container">
            <OutlinedBtn
              href="/"
              text={getBackToHomeLabel({ code: lang })}
              type="primary"
            />
            <MainBtn
              href={`${getBlogPostBaseUrl()}`}
              text={getBackToBlogPostsLabel({ code: lang })}
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default SingleBlogPost;
